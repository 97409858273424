
    #no-results{
        text-align: center;
        padding-top:150px;
        .no-results{
            span{
                font-family: 'Red Hat Display', sans-serif;
                border-bottom:1px solid black;
                padding:10px 5px;
            }

            margin-bottom:50px;
        }

        .no-prof{
            h5{
                font-size:18px;
                font-weight:bold;
                margin-bottom:8px;
            }
            p{
                font-family: 'Lato', sans-serif;
                font-size:12px;
                margin-bottom:30px;
            }
            button{
                font-family: 'Lato', sans-serif;
                font-weight:bold;
                cursor: pointer;
                font-size:12px;
                background-color:var(--highlight2);
                color:white;
                padding:12px 20px;
                border:none;
                margin-right:8px;
            }
            .about-us{
                background-color:var(--blue);
            }
        }

        @media only screen and (min-width : 319px) and (max-width : 785px) {
            padding-top:100px;
            padding-bottom:calc(100vh - 380px);
            .no-results{
                margin-bottom:50px;
                span{
                    font-size:12px;
                }
            }
            .no-prof{
                margin:auto;
                width:200px;
                text-align: left;
                h5{
                    font-size:14px;
                }
                p{
                    margin-bottom:20px;
                }
                button{
                    width:200px;
                    margin-top:5px;
                }
            }
    
        }
    }
