#mobile-results{
    i{
        font-size:9px;
        display:none;
        margin-right:1px;
    }
    .flex{
        width:100%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:10px;
    }
    .list-item{
        width:100%;
        padding:10px 10px;
        margin-bottom:3px;
        box-sizing: border-box;
        border-bottom:1px solid grey;
    }

    .name{
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        font-size:12px;
    }
    .score{
        font-family: 'Lato', sans-serif;
        font-size:12px;
        align-self: flex-end;
        float:right;
    }
    .course{
        font-size:12px;
        margin:0 2px;
        flex-wrap: nowrap;
        white-space: nowrap;
        color:grey;
        font-family: 'Lato', sans-serif;
    }
    .courses{
        width:100%;
        display:flex;
        flex-direction: row;
        overflow-x: scroll;
        flex-wrap: nowrap;
    }

    .courses::-webkit-scrollbar {
        display: none;
      }
      
      .courses {
        -ms-overflow-style: none;
      }

}

#mobile-results.dark{
    .score,
    .name{
        color:white;
    }
    .list-item{
        background-color:rgb(27, 27, 27);
    }
}