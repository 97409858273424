#query{
    padding-top:65px;
    background:rgb(250, 250, 250);
    // background:   linear-gradient(108deg, rgb(11, 57, 94), #5d6dfd);

    .container2{
        padding-top:26px;
        //display:flex;
    }

    .sidenav.query{
        display:flex;
        flex-direction: row;
        border-bottom:1px solid rgb(235, 235, 235);
        .slogan{
            display:none;
        }
    }

    .sidenav.home{
        padding-top:100px;
        padding-bottom:calc(100vh - 550px);
        display:block;
        width:auto;
        text-align: left;
        width:460px;
        margin:auto;


        // display:flex;
        // flex-direction: row;
        // border-bottom:1px solid rgb(235, 235, 235);
    }

    .slogan{
        padding-top:20px;
        //padding-bottom:calc(100vh - 540px);
        font-weight: bold;
        color:var(--highlight2);
        h2{
            font-size:72px;
            margin-bottom:40px;
            width:600px;
            line-height:1;
        }
        p{
            font-family: 'Lato', sans-serif;
            font-weight:100;
            font-size:14px;
            color:#7A7A7A;
            margin-bottom:20px;
            line-height: 1.5;
            width:420px;
        }

        button{
            font-family: 'Lato', sans-serif;
            font-weight:bold;
            cursor: pointer;
            font-size:14px;
            background-color:var(--blue);
            color:white;
            padding:12px 18px;
            border:none;
            margin-right:8px;
            width:300px;
            transition:0.3s;
        }
        button:hover{
            background-color:var(--highlight2);
        }

    }

    .main{
        min-height: calc(100vh - 160px);
        padding-right:10px;
        padding-bottom:60px;
        width:100%;
    }

    .table{
        //width:100%;
        padding: 0 100px;

    }



    table{
        margin-top:50px;
        width:100%;
        column-gap:0;
        border-spacing:0;   
        border-collapse:separate; 
        border-spacing:0 4px; 

        a.sort-by:before,
        a.sort-by:after {
            border: 4px solid transparent;
            content: "";
            display: block;
            height: 0;
            right: -15px;
            top: 50%;
            position: absolute;
            width: 0;
        }
        a.sort-by:before {
            border-bottom-color: rgb(189, 189, 189);
            margin-top: -9px;
        }
        a.sort-by:after {
            border-top-color: rgb(189, 189, 189);
            margin-top: 1px;
        }

        a.sort-by.up:before{
            border-bottom-color:black;
        }

        a.sort-by.down:after{
            border-top-color: black;
        }

        th a.sort-by { 
            position: relative;
            display:inline;
            text-decoration: none;
            color:black;
        }

        a.sort-by:hover{
            color:var(--blue);
        }

        th{
            padding-left:45px;
            text-align: left;
            padding-bottom:2px;
            vertical-align: 0;
            padding-bottom:6px;

            .descript{
                font-size:12px;
                color:grey;
                font-family: 'Lato', sans-serif;
                font-weight:normal
            }

            div{
                font-family: inherit;
            }
            .legend{
                padding-top:2px;
                font-size:11px;
                font-family: 'Lato', sans-serif;
            }

            span{
                font-family: 'Lato', sans-serif;
                font-weight:normal;
                color: white;
                padding:2px 8px;
                border-radius: 10px;
            }

            .highlight-1{
                background-color:var(--highlight1);
            }

            .highlight-2{
                background-color:var(--highlight2);
            }
        }
            
        tr :not(th):not(div):not(a):not(i):not(span):not(#table-link){
            //border-top:1px solid rgb(196, 196, 196);
            //border-bottom:1px solid black;

            padding:23px 1px;
            padding-left:45px;
            cursor: pointer;
        }

        td{
            margin:0;
            border:none;
        }
    }

    @media (max-width : 1224px) {
        .table{
            padding:10px;
        }
    }

    @media only screen and (min-width : 319px) and (max-width : 834px) {
        display:none;
    }

}

#mobile-home{
        display:none;

    @media only screen and (min-width : 319px) and (max-width : 834px) {
        display:block;
    }
}

#query.dark{
    background:black;
}