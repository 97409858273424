#score{
  .dropbtn {
    font-family: 'Lato', sans-serif;
    padding:12px 14px;
    margin-bottom:5px;
    border-radius: 5px;
    border:1px solid transparent;
    cursor:pointer;
    font-size:14px;
    font-weight:bold;
    color:var(--blue);
    background-color:transparent;
    margin:0;

    span{
      font-family: 'Lato', sans-serif;
      font-size:11px;
      margin-right:5px;
      color:black;
    }
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    border-radius: 5px;
    button{
      cursor: pointer;
      padding:6px 18px;
      border:1px solid rgb(224, 224, 224);
      border-right:none;
      margin:0;
      background-color:rgb(236, 236, 236);
    }
    .first{
      border-top-left-radius:    8px;
      border-bottom-left-radius: 8px;
    }
    .last{
      border-top-right-radius:    8px;
      border-bottom-right-radius: 8px;
      border-right:1px solid rgb(224, 224, 224);

    }
    .selected{
      background-color:var(--blue);
      color:white;
    }


    label{
      font-family: 'Lato', sans-serif;

      display:block;
      font-size:12px;
      margin-bottom:4px;
      color:rgb(66, 66, 66);
    }
    display: none;
    position: absolute;
    background-color: white;
    //border:1px solid rgb(214, 214, 214);
    padding:10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
    z-index: 1;
  }


  .dropdown:hover .dropbtn{
    border:1px solid rgb(224, 224, 224);
  }
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

}

#score.mobile{

  .dropbtn {
    padding:10px 8px;
    border:1px solid var(--lightgrey);
    font-size:11px;
    margin-right:4px;
    margin-left:4px;
    

    span{
      font-family: 'Lato', sans-serif;
      font-size:11px;
      margin-right:5px;
    }
  }

  .dropbtn.dark{
    background-color:black;
    border:1px solid rgb(63, 63, 63);
    span{
      color:white;
    }
  }

  .dropdown-content{
    //background-color: rgb(88, 88, 88);
    label.dark{
      color:white;
    }

    button.dark{
      background-color:rgb(66, 66, 66);
      color:white;
      border-color:grey;
    }
    button.dark.selected{
      background-color:black;
    }
  }

  .dropdown-content.dark{
    background-color: rgb(88, 88, 88);
    label{
      color:white;
    }

    button{
      background-color:rgb(66, 66, 66);
      color:white;
      border-color:grey;
    }
    button.selected{
      background-color:black;
    }
  }

  // .dropbtn:hover .dropdown-content{
  //   display:none;
  // }

  .dropdown-content .show{
    display:block;
  }
}