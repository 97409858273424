#prof{
    padding-top:65px;
    padding-bottom:60px;
    .searchbar{
        padding-top:16px;
        border-bottom:1px solid rgb(236, 236, 236);
        box-shadow: 0px 1px 4px #c5c5c5;

    }
    .container2{
        text-align: center;
        align-items: center;
    }

    .heading{
        display:flex;
        padding-bottom:80px;
        padding-top:16px;
        margin-top:50px;
        border-top:1px solid black;
        .culpa-links{
            float:right;
            margin-left:auto;
            text-align: right;
            a{
                font-size:14px;
                color:var(--blue);
                text-decoration: none;
                text-transform: uppercase;
                display:block;
                font-family: 'Roboto', sans-serif;
                margin-top:6px;

            }
            a:hover{
                color:var(--highlight2)
            }
        }
    }

    .body{
        position: relative;
        display:flex;
        flex-direction: column;
        min-height:1100px;

        .par{
            font-size:14px;
            font-family: 'Mada', sans-serif;
            color:#7B7B7B;
            padding-top:24px;
            padding-bottom:50px;
        }

        h6{
            text-transform: uppercase;
            font-weight:bold;
            font-family: 'Roboto', sans-serif;
        }

        .chunk{
            border-top:1px solid rgba(0,0,0,.4);
            padding-top:10px;
            margin-bottom:80px;
            //background:white;
            //box-shadow: 0 2px 5px #eaeaea;
            //padding:10px;

        }
            .culpameter{
                border-top:none;
                box-shadow: 2px 2px 5px #c5c5c5;
                padding:14px;
                padding-top:20px;
                box-sizing: border-box;
                padding-bottom:40px;
                position: absolute;
                top: 0;
                left: 0;
                width: 240px;
                .num-reviews{
                    color:var(--highlight2);
                    font-weight:bold;
                }
                .score{
                    font-family: 'Roboto', sans-serif;
                    font-size:96px;
                    font-weight:100;
                }
            }

            .visuals{
                position: absolute;
                margin-top: 450px;
                left: 0;
                width: 240px;

                .chart-title{
                    font-weight:bold;
                    color:var(--highlight2);
                }
            }

        .main{
            margin-left:316px;
            .courses{
                font-size:14px;
                ul{
                    list-style: none;
                    padding:0;
                    padding-top:18px;
                }
            }
        }
    }

    @media only screen and (min-width : 319px) and (max-width : 785px) {
        padding-bottom:0;
        padding-top:40px;

        .searchbar{
            display:none;
        }
        .heading{
            padding-bottom:40px;
            margin-top:0px;
            flex-direction: column;
            h2{
                margin-bottom:10px;
            }
            .culpa-links{
                text-align: left;
                margin-left:0;
            }
        }
        .body{
            //min-height:0;

            .culpameter,
            .visuals{
                margin-bottom:50px;
                padding-left:10px;
                padding-right:10px;
                box-sizing: border-box;
            }
            
            .visuals{
                position:relative; 
                margin-top:0;
                order: 3;
                width:100%;
                .par{
                    width:260px;
                }

            }
            .culpameter{
                padding:18px;
                padding-bottom:40px;
                .culpameter-wrapper{
                    display:flex;
                }
                position: relative;
                order:1;
                width:100%;
                margin-bottom:60px;
                .score{
                    font-size:60px;
                    margin-right:10px;
                }
                .par{
                    padding:10px;
                }
            }
            .main{
                margin-left:0;
                order:2;
            }
        }
    }
}