#toggle-row{

   // transition:height 0.3s smooth;

    .title{
        padding:18px 0;
        padding-right:24px;
        border-top:1px solid rgba(0,0,0,.14);

        cursor: pointer;

        span, div{
            font-family: 'Mada', sans-serif;
            color:rgba(0,0,0,.6);
        }
        .id{
            margin-right:10px;
            color:rgb(56, 56, 56);

        }
        .name{
            text-overflow: ellipsis;
           // width:calc(100% - 230px);
            display: inline-flex;
        }
        .sem{
            float:right;
            padding-left:10px;
            color:#969696;
        }
        i{
            margin-left:8px;
            position: absolute;
            right:0;
            display:inline-block;
            vertical-align: baseline;
            font-size:17px;
            color: var(--blue);
        }
    }
    .title:hover i{
        color:var(--highlight2);
    }

    .toggle{
        color:#969696;
        padding-bottom:20px;
        font-family: 'Mada', sans-serif;
        display:none;
    }

    .toggle.open{
        display:block;
    }


    .other-prof-links{
        a{
            font-family: 'Mada', sans-serif;
            text-decoration: none;
            color:var(--blue);
        }
    }

    @media only screen and (min-width : 319px) and (max-width : 785px) {
        .title{
            .name{
                width:auto;
            }
        }
    }
}