.search{
    display:flex;
    height: 40px;
    margin-bottom:30px;


    .link{
        width:80px;
        text-align: center;
        text-decoration: none;
        margin-left:6px;
        background-color:black;
        color:white;
        font-family: 'Red Hat Display', sans-serif;
        font-size:14px;
        cursor:pointer;
        border:none;
        line-height: 35px;
        transition:background-color 0.2s;
        border-radius:4px;
    }


    .link:hover{
        background-color:var(--blue);
    }

    @media only screen and (max-width : 420px) {
        height:40px;
        #searchbar{
            font-size:12px;
            padding: 0 4px;
        }
    }
}

#searchbar:focus{
    border:2px solid black;
}
.search input[type=text]{
    width:100%;
    padding:6px 16px;
    // background-color:rgb(245, 245, 245);
    // border:none;
    border-radius:4px;
    border:2px solid var(--blue);
    font-family: 'Red Hat Display', sans-serif;
    font-size:14px;
}


.search.home{
    order:1;
    // order:2;
    // float:right;
    // align-self:flex-end;
    // margin-left:auto;
    #searchbar{
        width:550px;
        transition:width 0.2s;
    }

    // #searchbar:focus{
    //     width:530px;
    // }
    
    .link{
        display:none;
        width:70px;
        border-radius: 20px;
    }
    @media only screen and (max-width : 420px) {
        .link{
            width:44px;
            font-size:12px;
            line-height:35px;
            margin-left:2px;
        }
    }
}

.search.prof{
    margin-bottom:15px;
    // text-align: center;
    height:36px;
    // align-items: center;
    #searchbar{
        width:400px;
        background-color:rgb(238, 238, 238);
        border:none;
    }
    #searchbar:focus{
        border:2px solid black;
        background-color:transparent;
    }
    
}

.search.mobile{
    margin-bottom:12px;

    #searchbar{
        width:100%;
        padding: 0 12px;
        background-color:rgb(230, 230, 230);
        border:none;
    }

    #searchbar.dark{
        background-color:rgb(51, 51, 51);
        color:white;

    }

    #searchbar:focus{
        font-size:12px;
        // border:1px solid var(--blue);
    }
    .link{
        display:none;
    }
}

.search.query{
        order:2;
        float:right;
        align-self:flex-end;
        margin-left:auto;
    
    #searchbar{
        width:400px;
        transition:width 0.2s;
    }

    #searchbar:focus{
        width:530px;
    }
    
    .link{
        display:none;
    }
}


