#search-mobile-div{
    padding: 59px 0;
    padding-bottom:40px;
    min-height:calc(100vh - 130px);
    //background-color:rgb(26, 26, 26);
    //background-color:rgb(250, 250, 250);

    .contain{
        padding: 0 10px;
        background-color:white;
    }

    .contain.top{
        border-bottom:1px solid rgb(202, 202, 202);
        padding-bottom:5px;
    }
    .contain.dark.top{
        border-bottom:1px solid rgb(65, 65, 65);
    }
}

