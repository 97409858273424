#dept{
    
    .label{
        font-family: 'Lato', sans-serif;
        display:block;
        font-size:12px;
        padding-bottom:8px;
    }

    #select{
        font-family: 'Lato', sans-serif;
        border:none;
        padding:12px 0;
        padding-top:11px;
        background-color:transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size:14px;
        color:var(--blue);
        font-weight:bold;
       padding-left:98px;
       margin:0;
    }

    #custom-select {
        box-sizing: content-box;
        position: relative;
        padding-right:8px;
        border-radius: 5px;
        border:1px solid transparent;
      }
      #custom-select:hover {
       border:1px solid var(--lightgrey);
        cursor:pointer;
      }
      #select:hover{
        cursor:pointer;

      }

      #custom-select:before{
        font-family: 'Lato', sans-serif;
        position: absolute;
        content:'DEPARTMENT: ';
        left:10px;
        top: 14px;
        font-size:11px;
        font-weight:bold;
      }
    
}

#dept.mobile{

  #custom-select{
    border:1px solid var(--lightgrey);
    padding: 0px;
  }

  #custom-select.dark{
    border:1px solid rgb(63, 63, 63);
    background-color:black;
  }

  #custom-select:before{
    content:'DEPT: ';
    left:8px;
    top: 10px;
    font-size:11px;
  }

  #custom-select.dark:before{
    color:white;
  }

  #select{
    //margin-top:1px;
    padding:10px 8px;
    padding-top:10px;
    padding-left:46px;
    font-size:11px;
    height:auto;
  }
}