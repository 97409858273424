* {
    font-family: aktiv-grotesk, sans-serif;
}

:root {
    /* --hover-light-background: #5d6dfd; */
    --blue:#7BC8FF;
    --culpa:#130E91;
    --lightgrey: #e0e0e0;
    /* --blue: #ff462d; */
    /* --highlight1: #E7C200;
    --highlight2: #0057FF; */

    --highlight1: #7BC8FF;
    /* --highlight1: #ff462d; */
    /* --highlight2: #213877;  */
    --highlight2: #1d2135;

    /* --gradient: linear-gradient(108deg, var(--main-light-background), #5d6dfd); */
  }

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 2rem;
    font-weight: 300; }
  h1 { font-size: 4.0rem; line-height: 1.2;  letter-spacing: -.1rem;}
  h2 { font-size: 3.6rem; line-height: 1.25; letter-spacing: -.1rem; }
  h3 { font-size: 3.0rem; line-height: 1.3;  letter-spacing: -.1rem; }
  h4 { font-size: 2.4rem; line-height: 1.35; letter-spacing: -.08rem; }
  h5 { font-size: 1.8rem; line-height: 1.5;  letter-spacing: -.05rem; }
  h6 { font-size: 1.5rem; line-height: 1.6;  letter-spacing: 0; }
  
  /* Larger than phablet */
  @media (min-width: 550px) {
    h1 { font-size: 5.0rem; }
    h2 { font-size: 4.2rem; }
    h3 { font-size: 3.6rem; }
    h4 { font-size: 3.0rem; }
    h5 { font-size: 2.4rem; }
    h6 { font-size: 1.5rem; }
  }

  input:focus, textarea:focus, button:focus, select:focus{
    outline: none;
  }

/* ****************************************************** */


h1 {
  font-family: 'Lato', sans-serif;
  font-size: 60px;
    line-height: 1.03;
    letter-spacing: -0.3px;
}

h2 {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 42px;
  font-weight:bold;
  line-height: 1.22;
  letter-spacing: -0.2px;
  -webkit-font-smoothing: antialiased;
}

h3 {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
}

h6 {
  font-family: 'Lato', sans-serif;
    font-size: 14px ;
    line-height: normal;
    letter-spacing: normal;
}

h5 {
    font-family: 'Montserrat', sans-serif;
    font-size:18px ;
    line-height: 1.33 ;
    letter-spacing: -0.1px;
}

h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    line-height: 1.7;
    letter-spacing: -0.1px;
}

.slide-right,.slide-left,.slide-down,.fade-in{
  opacity: 0;
}
.slide-right.in-view{
    opacity:1;
    animation: SlideRight 0.5s forwards;
}
.slide-left.in-view{
    opacity:1;
    animation: SlideLeft 0.5s forwards;
}
.slide-down.in-view{
  opacity:1;
  animation: SlideDown 0.5s forwards;
}
.fade-in.in-view{
  animation:FadeIn 0.8s ease forwards;
}

@keyframes SlideLeft{
    0% {
      transform: translateX(60px);
    }
    100% {
      transform: translateX(0);
    }
  }
@keyframes SlideRight{
    0% {
      transform: translateX(-60px);
    }
    100% {
      transform: translateX(0);
    }
}

@keyframes ZoomIn{
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.15);
    }
}

@keyframes SlideDown {
    0% {
      transform: translateY(-40px);
      visibility: hidden;
    }
    100% {
      transform: translateY(0);
      visibility: visible;
    }
  }

@keyframes FadeIn{
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}


.container {
    max-width: 1000px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.container2{
  max-width: 1270px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

body {
    margin: 0;
}

p{
  font-family: Montserrat;
}

.small-head{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.1px;
    text-align: center;
    text-transform: uppercase;
}

p{
    margin-bottom:2.5rem;
}
/* Smartphones (portrait and landscape) ----------- */
@media (min-width : 319px) and (max-width : 504px) {
    h1 {
        font-size: 30px;
        line-height: 1.03;
        letter-spacing: -0.3px;
    }

    h2{
        font-size: 28px;
    }

    .small-head{
        font-size:14px;
    }

    h5{
        font-size: 16px;
    }
    .container {
      width: 95%;
      padding: 0; }

      .container2 {
        width: 95%;
        padding: 0; }
  }

  @media (min-width: 400px) {
    .container2,
    .container {
      width: 90%;
      padding: 0; }
  }
  
  /* For devices larger than 550px */
  @media (min-width: 550px) {
    .container2,
    .container {
      width: 85%; }
    }

