#loading{
        display:block;
        text-align: center;
        padding: calc((100vh - 200px)/2) 0;
        align-items: center;
          

@keyframes ldio-2xo812k5n4l {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.45,0,0.9,0.55)
  }
  0% {
    transform: translate(0,0)
  }
  50% {
    transform: translate(0,108px);
    animation-timing-function: cubic-bezier(0,0.45,0.55,0.9);
  }
  100% {
    transform: translate(0,0);
  }
}

.ldio-2xo812k5n4l div {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--blue);
  left: 74px;
  top: 20px;
  animation: ldio-2xo812k5n4l 1s linear infinite;
}

.loadingio-spinner-ball-1ebmtkkee8t {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-2xo812k5n4l {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-2xo812k5n4l div { box-sizing: content-box; }
/* generated by https://loading.io/ */
}
