#workload-comments{

    padding-top:30px;
    .course-block{
        display:flex;
        font-size:14px;
        margin-bottom:30px;
        padding-top:30px;
       
    }

    .course-block:hover {
        box-shadow: inset 0px 1px   rgb(226, 226, 226);
        //border-top:1px solid rgb(226, 226, 226);
        //border-bottom: 1px solid rgb(226, 226, 226);
    }
    .course{
        font-family: 'Mada', sans-serif;
        //border:1px solid var(--blue);
        display:inline-block;
        padding:5px 0px;
        border-radius: 4px;
        background-color:var(--highlight2);
        color:white;
        width:112px;
        margin-right:32px;
        text-align: center;
        margin-bottom:20px;
        cursor: default;
    }

    .course-name{
            display:none;
            position: absolute;
            margin-top:29px;
            background-color:white;
            color:black;
            padding:3px 12px;
            font-size:12px;
            margin-left:-4px;   
            border-radius: 10px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }

    .course:hover .course-name{
        display:block;
    }

    .comment-block{
        display:block;
        padding-bottom:10px;
        width:100%;

        .show-all{
            float:right;
            border:none;
            cursor:pointer;
            text-decoration: underline;
            color:grey;
            background-color:transparent;
        }
        .show-all:hover{
            color:black;
        }

        .show-all .show{
            content:'show less';
        }
    }

    .comments{
        display:flex;
        margin-bottom:25px;

        .date{
            font-family: 'Mada', sans-serif;
            margin-right:10px;
            width:80px;
            color:grey;
            display:inline-block;
            flex-shrink: 0;
        }
        .text{
            p{
                font-family: 'Mada', sans-serif;
                margin-top:0;  
                margin-bottom:2px;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }

            input:checked + p {
                -webkit-line-clamp: unset;
              }

              input {
                opacity: 0;
                position: absolute;
                pointer-events: none;
              }

              label{
                  cursor:pointer;
                  color:var(--blue);
                  font-size:12px;
              }

            label::after {
                content: "read more";
            }

            input:checked ~ label::after {
                content: "show less";
            }

            p:not(.truncated) ~ label{
              display: none;
            }
        }
    }



    @media only screen and (min-width : 319px) and (max-width : 785px) {
        padding-top:0;
        .course-block{
            display:block;
            padding-top:10px;
        }
        .comments{
            display:block;
        }

        .comment-block{
            .show-all{
                background-color:transparent;
            }
        }

    }
}