#sentences{
    
    height:250px;
    overflow-x:scroll;
    overflow-y:hidden;
.sentence::-webkit-scrollbar {
    display: none;
  }
  
  .sentence {
    -ms-overflow-style: none;
  }
    display:flex;

    .sentence{
        flex-shrink:0;
        margin:16px 3px;
        font-size:14px;
        padding:20px 16px;
        padding-top:10px;
        width:115px;
        background-color:rgb(252, 252, 252);

        border-radius: 4px;
        overflow: scroll;
        font-family: 'Lato', sans-serif;
        transition:0.3s;
        box-shadow: 3px 6px 5px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 2px 2px 5px #dddddd;


        .date{
            color:var(--blue);
            width:100%;
            text-align:right;
            font-size:11px;
            margin-bottom:4px;
            transform:translateX(5px);
            display:none;
        }
    }
    .sentence:hover .date{
            display:block;
            animation: 0.3s FadeIn ease-in;
    }
    .sentence:hover {
        animation:0.3s Grow ease;
    }

    @media only screen and (min-width : 319px) and (max-width : 785px) {
        height:210px;
        .sentence{
            width:94px;
            font-size:12px;

            .date{
                font-size:10px;
            }
        }

    }

    @keyframes FadeIn {
        0%{
            opacity: 0;
        }
        100%{
            opacity:1;
        }
    }
    
    @keyframes Grow {
        0%{
            padding-top: 0;
        }
        100%{
            padding-top:10;
        }
    }
}

#sentences::-webkit-scrollbar {
    display: none;
  }
  
  #sentences {
    -ms-overflow-style: none;
  }

#invisible{
    display:none;
}

