#mobile-home{
    padding-top:20px;
    height:calc(100vh - 100px);
    background:rgb(250, 250, 250);
    .container{
        width:80%;
    }
    .search.mobile-home{
        margin-bottom:20px;
    }
    .slogan{
        padding-top:100px;
        padding-bottom:calc(100vh - 520px);
        font-weight: bold;
        color:var(--highlight2);
        h2{
            font-size:50px;
            margin-bottom:35px;
            line-height:1.1;
            max-width: 400px;
        }
        p{
            font-family: 'Lato', sans-serif;
            font-size:14px;
            color:#7A7A7A;
            margin-bottom:18px;
            line-height: 1.5;
            font-weight:100;
            max-width:330px;
        }

        button{
            font-family: 'Lato', sans-serif;
            font-weight:bold;
            cursor: pointer;
            font-size:14px;
            background-color:var(--highlight2);
            color:white;
            padding:12px 22px;
            border:none;
            margin-right:8px;
            width:200px;
            //width:100%;
            transition:0.3s;
        }
        button:hover{
            background-color:var(--highlight2);
        }

    }
}