#about{
    padding-top:210px;
    padding-bottom:100px;
    background:rgb(250, 250, 250);

    //min-height:calc(100vh - 220px);

    .container{
        //padding:0 140px;
    }
    h5{
        font-family: 'Red Hat Display', sans-serif;
        font-weight:bold;
        font-size:41px;
        margin-bottom:40px;
        color:var(--highlight2);
        .logo{
            font-family: 'Roboto', 'san-serif';
            color:var(--highlight2);
        }
    }
    p{
        line-height:1.5;
        letter-spacing: 0.5px;
        font-family: 'Lato', sans-serif;
        //margin-bottom:20px;
    }

    .tagline{
        margin-bottom:10px;
    }

    hr{
        width:150px;
        float:left;
        color:black;
        border:0.5px solid grey;
        margin-top:25px;
    }
    .culpa{
        color:black;
        font-family: 'Mada', 'san-serif';
        font-weight: bold;
    }

    .contact{
        margin-top:70px;
        color:grey;
        a{
            text-decoration: none;
            color:var(--highlight2);
        }
    }

    @media only screen and (min-width : 319px) and (max-width : 785px) {
        padding-top:60px;

    }
}