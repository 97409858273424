#faq{
    padding-top:58px;
    // .collapsible {
    //     background-color: transparent;
    //     margin-top:28px;
    //     cursor: pointer;
    //     padding: 10px 0;
    //     width: 100%;
    //     border: none;
    //     text-align: left;
    //     outline: none;
    //     height:auto;
    //     border-bottom:1px solid black;
    //     border-radius: 0;
    //     color:var(--highlight2);
    //     text-transform: capitalize;
    //     display:flex;

    //     h3{
    //         font-family: 'Red Hat Display';
    //         //font-weight:bold;
    //         text-transform: none;
    //         font-size:20px;
    //         margin-bottom:14px;
    //         padding-right:25px;
    //     }

    //     .close {
    //         margin-left:auto;
    //         margin-right:18px;
    //         width: 20px;
    //         height: 20px;
    //     }
    //     .close:before, .close:after {
    //         position: absolute;
    //         content: ' ';
    //         height: 20px;
    //         width: 2px;
    //         background-color:black;
    //     }
    //     .close:after {
    //         transform: rotate(-90deg);
    //     }
    // }

    // .collapsible:hover h3{
    //     color:var(--blue);
    // }
      

    // .collapsible.open {
    //     border:none;
    //     color:var(--blue);
    //     .close:before {
    //         transform: rotate(90deg);
    //     }
    // }
      

    // .content {
    //     padding: 0;
    //     display: none;
    //     border-bottom:1px solid black;
    //     padding-bottom:14px;
    //     max-height: 0;
    //     transition: max-height 0.4s ease-out;

    //     .answer{
    //         padding-bottom:20px;
    //         margin-bottom:20px;
    //     }
    // }

    .accordion {
        background-color:transparent;
        cursor: pointer;
        padding: 18px 0;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
        font-size:14px;
        font-family:"Lato", sans-serif;
        border-top:1px solid rgb(223, 223, 223);
      }

      .accordion:after {
        content: '\02795'; /* Unicode character for "plus" sign (+) */
        font-size: 12px;
        color: var(--orange);
        float: right;
        margin-left: 5px;
      }
      
      .active:after {
        content: "\2796"; /* Unicode character for "minus" sign (-) */
      }
      
      /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
      .active, .accordion:hover {
        //background-color: #ccc;
        color:rgb(94, 94, 94);
      }
      
      /* Style the accordion panel. Note: hidden by default */
      .panel {
        //padding: 0 18px;
        background-color: transparent;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.2s ease-out;
      }

      p{
          font-family: "Lato", sans-serif;
          font-weight:300;
          font-size:13px;
      }

    @media only screen and (min-width : 319px) and (max-width : 500px) {
        .collapsible{
            h3{
                font-size: 18px;
            }
        }


    }
}