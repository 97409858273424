footer {
border-top:0.5px solid rgb(221, 221, 221);
background:rgb(250, 250, 250);
.footer-links{
        padding-top:20px;
        display:flex;
        font-family: 'Mada', sans-serif;
        font-size: 14px;

        
        a{
            font-family: 'Mada', sans-serif;
            text-decoration: none;
            padding-left: 30px;
            cursor: pointer;
            font-size: 14px;
            color:black;
        }

        .email{
            float:right;
            margin-left:auto;
        }

        .logo{
            color:var(--blue);
            font-weight: bold;
            padding-right:30px;
            padding-left:0;
        }
    }

    a:hover{
        color:var(--card-color);
    }

    @media only screen and (min-width : 319px) and (max-width : 504px) {
        padding-bottom:12px;
        height:auto;

        .footer-links{
            font-size:12px;
            a{
                font-size:12px;
                padding-left:12px;
            }
            .logo{
                padding-right:12px;
            
            }
        }
        .lower-footer{
            font-size:11px;
        }
    }
}


.lower-footer {
    font-size: 12px;
    padding-top: 60px;
    padding-bottom:24px;
    font-family: Montserrat;
    text-align: center;
    font-family: 'Mada', sans-serif;
}