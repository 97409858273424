#head{
  background-color: rgb(29, 33, 53);
  border-bottom:1px solid rgb(212, 212, 212);
  display: block;
  height: 65px;
  width: 100%;
  z-index: 1;
  position:fixed;
  
 
  .logo-div {
    width:170px;
    font-size: 26px;
    padding-top:15px;
    a{
      color:var(--blue);
    }

  }

  a {
    text-decoration: none;
    font-weight:bold;
    font-family: 'Mada', sans-serif;
    color:white;

  }

  i{
    color:black;
    font-size:14px;
    margin-right:4px;
  }

  .container2{
    display:flex;
  }

  .button-menu:hover{
    //border-bottom: solid 3px var(--main-dark-background);
    cursor: pointer;
  }
  .menu-item{
    padding-left:53px;
    margin-right:30px;

    a {
      text-decoration: none;
      font-weight:normal;
      font-family: 'Mada', sans-serif;
    }
  }

  .menu{
    display: flex;
    margin-left:auto;
    padding-top:22px;

  }

  .mobile-menu{
    display: none;
  }

  #search-mobile,#search-mobile-div{
    display:none;
  }










  .switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 21px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--blue);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }












  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-width : 319px) and (max-width : 834px) {
    padding-top:0px;
    position: relative;
    height:54px;
    //text-align: center;
    #logo-div{
      top:20;
      left:calc(50% - 82px);
      z-index:100;
      position: absolute;
      font-size: 20px;
    }
    .container2{
      text-align:center;
      display:block;
    }
    .menu {
      display: none;
    }

    .mobile-menu{
      display: block;
      visibility:visible;
      position: fixed;
      width:100%;
    }


    .mobile-menu #myLinks{
      padding:150px 30px;
      background-color: rgb(29, 33, 53);
      width:100%;
      display: none;
      margin-top:-65px;
    }

    .mobile-menu a{
        color: white;
        padding: 20px 16px;
        text-decoration: none;
        font-size: 14px;
        display: block;
        width:100%;
    }

    .mobile-menu .icon {
      display: inline-block;
      position: absolute;
      margin-top:-20px;
      color:white;
      opacity:1;
      font-size:30px;
      i{
        padding-right:40px;
        padding-top:35px;
        float:right;
        
      }
    }

    .mobile-menu a:active {
      color:white;
    }

    #search-mobile{
      display:block;
      position: absolute;
      right:20px;
      top:14px;
      z-index: 100;
      margin:0;

      svg{
        width:24px;
        height:inherit;
      }

      #search-path{
        fill:white;
      }

      #close-path{
        fill:black;
        display:none;
      }
      #close-path.dark{
        fill:white;

      }

    }

    .search-mobile-div{
      display: block;
      visibility:visible;
      //position: fixed;
      position: relative;
      width:100%;
      background-color:white;
    }

    #search-mobile-div{
      transition: opacity 0.3s ease-in;
      

    }


    #burgerBtn {
      border-top:1px solid white;
      height:20px;
      width:28px;
      box-sizing:border-box;
      position:absolute;
      z-index:30;
      left:20px;
      top:16px;
      cursor:pointer;
      &:before {
        content:"";
        display:block;
        position:absolute;
        height:1px;
        width:28px;
        left:0;
        background:white;
        top:8px;
        transition:0.6s;
      }
      &:after {
        content:"";
        display:block;
        position:absolute;
        height:1px;
        width:28px;
        left:0;
        background:white;
        bottom:0;
        transition:0.3s ease-in;
      }
    }
    

    .navigation {
      #burgerBtn {
        border-color:transparent;
        &:before {
          background:white;
          transform:  rotate(45deg);
          width:28px;
          left:-2px;
        }
        &:after {
          background:white;
          transform:  rotate(135deg);
          bottom:11px;
          width:28px;
          left:-2px;
        }
      }
    }
  }

  @media (min-width : 504px) and (max-width : 834px) {
    .logo-div{
      //padding-top:40px;
      z-index:100;
      //width:200px;
    }

    #burgerBtn {
      right:40px;
    }
  }

}