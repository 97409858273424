#table-row{
    //cursor:pointer;
     background-color:rgb(255, 255, 255);
    box-shadow: 2px 2px 5px #c5c5c5;

    #table-link{
        font-size:16px;
        color:black;
    }

    .prof{
        box-shadow: inset 3px 0px var(--blue);
        width:180px;
        font-weight: 400;
        transition: box-shadow 0.3s;
        font-family: 'Lato', sans-serif;
    }

    td{
        box-shadow: inset 0px 0px #7BC8FF;
        transition: box-shadow 0.3s;
    }


    td:last-child{
        padding-right:16px !important;
    }

    #divtoshow{
        position: fixed;
        display: none;
    }

    .course{
        font-family: 'Lato', sans-serif;
        font-size:12px;
        padding:2px 5px;
        margin-bottom:7px;
        margin-right:3px;
        cursor: default;

        span{
            display:none;
            position: absolute;
            margin-top:-23px;
            background-color:white;
            color:black;
            padding:3px 9px;
            font-size:11px;
            margin-left:-4px;   
            border-radius: 10px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

           // border:1px solid black;
        }

        i{
            font-size:9px;
            display:none;
            margin-right:1px;
        }

        i.sem-one{
            display:inline;
            color:var(--highlight1);
        }

        i.sem-two{
            display:inline;
            color:var(--highlight2);
        }
    }

    .course:hover span{
        display:block;
    }

    .course-div{
        width:205px;
        height:52px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        margin-right:-4px;
        align-content: flex-start;
    }

    .course-div::-webkit-scrollbar {
        display: none;
      }
      
      .course-div {
        -ms-overflow-style: none;
      }

    .score{
        font-size:20px;
        font-weight:100;
        cursor:default;

        span{
            padding:2px 10px;
            //border-radius: 15px;
            //border:1px solid;
            //background-color:var(--blue);
        }
    }

    a{
        background-color:black;
        padding:4px 14px;
        font-weight:100;
        color:white;
        font-size:12px;
        text-decoration: none;
        font-family: 'Lato', sans-serif;
    }
    a:hover{
        background-color: var(--highlight2);
        //background-color: white;
    }


    .num-reviews{
        font-family: 'Lato', sans-serif;
        font-size: 13px;
        margin-right:10px;
        margin-bottom:5px;
    }

}

#table-row:hover .prof{
    box-shadow: inset 3px 0px var(--highlight2);
}
