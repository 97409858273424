#filter-group{
    .clear-button{
        display:none;
        border:none;
        padding:0;
        //margin-top:20px;
        margin-bottom:auto;
        color:black;
        cursor:pointer;
        text-decoration: underline;
        background-color:transparent;
    }

}
// #filter-group.home,
#filter-group.query{
    order:1
}


#filter-group.home{
    margin-left:-14px;
}
#filter-group.query,
#filter-group.home{

        display:flex;

        .filter-item{
            margin:0;
        }
        .filter-dept{
            margin:0;
        }
        .filter-score{
            margin:0;
        }
        .clear-button{
            margin-left: 20px;
            flex-wrap: nowrap;
        }
        
}

#filter-group.mobile{
    display:flex;
    overflow-x:scroll;
    margin-bottom:3px;
    
}

